.b-header {
	--header-space_without_header_height: calc(100vh - var(--header-height, 0));

	display: grid;
	grid-template:
		[start promo-s background-s]
		auto //1
		[promo-e links-s logo-s utility-s]
		auto //2
		[links-e logo-e utility-e nav-s]
		auto //3
		[end background-e nav-e]
		/
		[start promo-s background-s]
		minmax(var(--grid-margin), calc((100vw - #{$global-content-width-max}) / 2))
		[links-s nav-s]
		1fr //1
		[]
		1fr //2
		[]
		1fr //3
		[links-e ]
		1fr //4
		[logo-s]
		1fr //5
		[]
		1fr //6
		[]
		1fr //7
		[]
		1fr //8
		[logo-e]
		1fr //9
		[utility-s]
		1fr //10
		[]
		1fr //11
		[]
		1fr //12
		[utility-e nav-e]
		minmax(var(--grid-margin), calc((100vw - #{$global-content-width-max}) / 2))
		[end promo-e background-e];
	position: fixed;
	top: 0;
	width: 100%;
	z-index: z(header);

	@include media(md-down) {
		grid-template:
			[start promo-s background-s]
			auto //1
			[promo-e hamburger-s logo-s utility-s]
			auto //2
			[hamburger-e logo-e utility-e nav-s background-e]
			1fr //3
			[end nav-e]
			/
			[start promo-s nav-s background-s]
			minmax(var(--grid-margin), calc((100vw - #{$global-content-width-max}) / 2))
			[hamburger-s]
			auto
			[hamburger-e logo-s]
			1fr
			[logo-e utility-s]
			auto
			[end utility-e]
			minmax(var(--grid-margin), calc((100vw - #{$global-content-width-max}) / 2))
			[end promo-e nav-e background-e];
		height: 100%;
		pointer-events: none;

		&::after {
			background-color: rgba($color-overlay-background, 0.5);
			content: '';
			grid-area: nav-s/nav-s/nav-e/nav-e;
			opacity: 0;
			transition: opacity 0.5s linear 0.2s;
		}
	}

	&-background {
		background-color: var(--color-header-background, $color-header-background);
		border-bottom: 1px solid $color-main_nav-divider;
		grid-area: background-s/background-s/background-e/background-e;
		transition: background-color 0.5s, color 0.5s;
	}

	&-promo {
		grid-area: promo-s/promo-s/promo-e/promo-e;
		pointer-events: all;
	}

	&-hamburger_cta_container {
		align-self: center;
		grid-area: hamburger-s/hamburger-s/hamburger-e/hamburger-e;
		line-height: 1;
		pointer-events: all;

		@include media(lg-up) {
			display: none;
		}
	}

	&-hamburger_cta {
		@include icon(hamburger, $icon-width: 17px, $icon-height: 15px);

		cursor: pointer;
		margin-left: rh(-1);
		padding: rh(3 1);
	}

	&-close_hamburger {
		@include icon(close, $icon-width: 16px, $icon-height: 16px);

		cursor: pointer;
		display: none;
		left: 5px;
		padding: rh(3 2);
		pointer-events: all;
		position: absolute;
		top: -57px;
	}

	&-links {
		display: flex;
		grid-area: links-s/links-s/links-e/links-e;
		justify-content: flex-start;

		@include media(lg-up) {
			margin-left: rh(-3);
			margin-top: $configuration-header-offset_top;
		}
	}

	&-links_item {
		align-items: center;
		display: flex;

		&:hover {
			text-decoration: none;
		}

		&.m-stores {
			padding: rh(3); // to increase clickable area

			@include media(md-down) {
				padding: rh(2); // to increase clickable area
			}

			@include icon(location, $icon-width: 13px, $icon-height: 17px) {
				margin-right: rh(2);
			}
		}
	}

	&-logo {
		grid-area: logo-s/logo-s/logo-e/logo-e;

		@include media(lg-up) {
			margin: $configuration-header-offset_top auto 0;
			text-align: center;
		}

		@include media(md-down) {
			align-items: center;
			display: flex;
			height: 70px;
			margin-left: rh(5);
			pointer-events: all;
			justify-content: center;
		}
	}

	&-utility {
		align-items: center;
		display: flex;
		grid-area: utility-s/utility-s/utility-e/utility-e;
		justify-content: flex-end;
		margin-right: rh(-2);

		@include media(lg-up) {
			margin-right: rh(-3);
			margin-top: $configuration-header-offset_top;
		}

		@include media(md-down) {
			pointer-events: all;
		}
	}

	&-utility_item {
		align-items: center;
		display: flex;

		@include media(md-down) {
			font-size: 0;
		}

		&:hover {
			text-decoration: none;
		}

		&.m-search {
			cursor: pointer;
			padding: rh(3); // to increase clickable area

			@include media(md-down) {
				padding: rh(2); // to increase clickable area
			}

			@include icon(search, $icon-width: 17px, $icon-height: 17px) {
				@include media(lg-up) {
					margin-right: rh(2);
				}
			}
		}

		&.m-favorites {
			@include icon(heart, $icon-width: 17px, $icon-height: 16px, $position: after);

			padding: 15px;
		}

		&.m-favorites_active {
			@include icon(wishlist-active, $icon-width: 17px, $icon-height: 16px, $position: after) {
				background: $color-accent;
			}
		}
	}

	&-utility_item.m-stores,
	&-utility_item.m-ship_to {
		display: none;
		
	}

	&-utility_item.m-stores {
		padding: rh(3); // to increase clickable area

		@include media(md-down) {
			padding: rh(2); // to increase clickable area
		}
	}

	&-navigation {
		grid-area: nav-s/nav-s/nav-e/nav-e;

		@include media(md-down) {
			height: 100%;
			position: relative;
			transition: visibility 0.5s linear;
			visibility: hidden;
		}
	}

	&-navigation_bottom {
		.b-customer_service_info {
			margin: rh(7 5);
		}
	}

	&-navigation_inner {
		@include media(md-down) {
			background: $color-hamburger_menu-background;
			height: 100%;
			left: -100%;
			opacity: 0;
			overflow: auto;
			pointer-events: all;
			position: absolute;
			transition: opacity 0.5s linear, left 0.5s linear;
			width: 100%;
			z-index: 2;
		}

		@include media(md) {
			min-width: 375px;
			width: 50%;
		}
	}

	&-navigation_link {
		padding: rh(0 3);
	}

	&-navigation_link_inner {
		@include media(md-down) {
			border-top: 1px solid $color-mobile_navigation-categories_divider;
			display: block;
			padding: rh(6 2);
		}
	}

	&-navigation_link.m-stores &-navigation_link_inner {
		align-items: center;
		display: flex;

		@include icon(location, $icon-width: 11px, $icon-height: 15px) {
			margin-right: rh(2);
		}
	}
	
	&-account {
		@include media(md-down) {
			background-color: $color-hamburger_account-background;
			border-bottom: 1px solid $color-mobile_navigation-section_divider;
			border-top: 1px solid $color-mobile_navigation-section_divider;
			padding: rh(7 5);
		}
	}

	// LEFT ALIGNED HEADER
	&.m-left_aligned {
		@include media(lg-up) {
			grid-template:
				[start promo-s background-s]
				auto //1
				[promo-e logo-s nav-s utility-s]
				auto //2
				[end logo-e nav-e utility-e background-e]
				/
				[start promo-s background-s]
				30px
				[logo-s]
				auto //1
				[logo-e nav-s]
				1fr //2
				[]
				1fr //3
				[]
				1fr //4
				[]
				1fr //5
				[]
				1fr //6
				[]
				1fr //7
				[]
				1fr //8
				[]
				1fr //9
				[nav-e utility-s]
				1fr //10
				[]
				1fr //11
				[]
				1fr //12
				[utility-e]
				30px
				[end promo-e background-e];
		}

		@include media(md-down) {
			grid-template:
				[start promo-s background-s]
				auto //1
				[promo-e hamburger-s logo-s utility-s]
				auto //2
				[hamburger-e logo-e utility-e background-e nav-s]
				1fr //3
				[end nav-e]
				/
				[start promo-s background-s nav-s]
				15px //gap
				[hamburger-s]
				1fr //1
				[hamburger-e logo-s]
				auto //2
				[logo-e utility-s]
				1fr //3
				[end utility-e]
				15px //gap
				[end background-e promo-e nav-e];
		}
	}

	&.m-left_aligned .b-categories_navigation {
		padding: rh( 0 5);

		&-list_1 {
			justify-content: flex-start;

			@include media(lg-up) {
				padding: rh(8 0 0);
			}
		}

		&-link_1 {
			@include media(lg-up) {
				padding: rh(0 0 7);
			}
		}
	}

	&.m-left_aligned &-logo {
		align-self: center;
		margin: 0;
		text-align: left;

		@include media(md-down) {
			align-items: center;
			display: flex;
			height: 52px;
			margin: rh(3) auto;
		}
	}

	&.m-left_aligned &-utility {
		margin-top: 0;
	}

	&.m-left_aligned &-utility_item.m-stores,
	&.m-left_aligned &-utility_item.m-ship_to {
		display: block;
		font-size: 14px;
		max-width: 150px;
		opacity: 1;
		transition: max-width 0.3s, font-size 0.3s, margin 0.3s;
		visibility: visible;
	}

	&.m-left_aligned &-utility_item.m-search,
	&.m-left_aligned &-utility_item.m-stores,
	&.m-left_aligned .b-header_login-title {
		font-size: 0;
	}

	&.m-left_aligned &-utility_item.m-stores {
		@include icon(location, $icon-width: 13px, $icon-height: 17px);
	}

	&.m-left_aligned &-links {
		display: none;
	}

	&.m-left_aligned &-close_hamburger {
		top: -58px;
	}

	&.m-left_aligned .b-header_login-title_link,
	&.m-left_aligned &-utility_item.m-search {
		&::before {
			margin-right: 0;
		}
	}

	// TRANSPARENT HEADER
	&.m-transparent:not(.m-active):not(.m-stuck):not(.m-hamburger_opened) {
		--color-header-background: transparent;

		@include hover-supported {
			&:hover {
				color: inherit;

				.b-logo-image {
					fill: currentColor;
				}

				.b-header_promo {
					background-color: $color-header_promo-background;
				}

				.b-header-background {
					background-color: $color-header-background;
					border-color: $color-main_nav-divider;
				}
			}
		}
	}

	&.m-transparent:not(.m-inverted):not(.m-active):not(.m-stuck):not(.m-hamburger_opened) {
		color: $color-header__transparent-text;

		.b-logo-image {
			fill: $color-header__transparent-text;
			transition: fill 0.5s;
		}
	}

	&.m-transparent.m-inverted:not(.m-active):not(.m-stuck):not(.m-hamburger_opened) {
		color: $color-header__transparent__inverted-text;

		.b-logo-image {
			fill: $color-header__transparent__inverted-text;
			transition: fill 0.5s;
		}
	}

	&.m-transparent:not(.m-active):not(.m-stuck):not(.m-hamburger_opened) &-background {
		border-color: $color-header__transparent-background-border;
	}

	&.m-transparent:not(.m-active):not(.m-stuck):not(.m-hamburger_opened) .b-header_promo {
		background-color: $color-header__transparent-header_promo-background;
	}

	// HAMBURGER NAVIGATION OPENED
	&.m-hamburger_opened::after {
		@include media(md-down) {
			opacity: 1;
		}
	}

	&.m-hamburger_opened &-close_hamburger {
		display: block;
	}

	&.m-hamburger_opened &-hamburger_cta_container {
		opacity: 0;
		visibility: hidden;
	}

	&.m-hamburger_opened &-navigation {
		visibility: visible;

		&::before {
			display: block;
		}
	}

	&.m-hamburger_opened &-navigation_inner {
		@include media(md-down) {
			left: 0;
			opacity: 1;
		}
	}

	// STICKY HEADER
	&.m-stuck {
		--header-space_without_header_height: calc(100vh - var(--header-sticky-height, 0));

		@include media(lg-up) {
			grid-template:
				[start background-s promo-s]
				auto //1
				[promo-e logo-s nav-s utility-s]
				auto //2
				[end background-e logo-e nav-e utility-e ]
				/
				[start promo-s background-s]
				30px
				[logo-s]
				1fr //1
				[]
				1fr //2
				[logo-e nav-s]
				1fr //3
				[]
				1fr //4
				[]
				1fr //5
				[]
				1fr //6
				[]
				1fr //7
				[]
				1fr //8
				[]
				1fr //9
				[]
				1fr //10
				[nav-e utility-s]
				1fr //11
				[]
				1fr //12
				[utility-e]
				30px
				[end promo-e background-e];
		}
	}

	// stylelint-disable-next-line no-descending-specificity
	&.m-stuck .b-header_promo {
		max-height: 0;
		opacity: 0;
		padding: 0;
		visibility: hidden;
	}

	&.m-stuck &-logo {
		@include media(lg-up) {
			align-self: center;
			margin: 0;
			text-align: left;
		}
	}

	&.m-stuck.m-left_aligned &-logo {
		@include media(md-down) {
			margin: rh(3);
		}
	}

	&.m-stuck &-utility {
		margin-top: 0;
	}

	&.m-stuck &-utility_item.m-search,
	&.m-stuck &-utility_item.m-account {
		font-size: 0;
	}

	&.m-stuck .b-header_login-title_link,
	&.m-stuck &-utility_item.m-search {
		&::before {
			margin-right: 0;
		}
	}

	&.m-stuck &-links {
		display: none;
	}

	&.m-stuck.m-left_aligned {
		@include media(lg-up) {
			grid-template:
				[start promo-s background-s]
				auto //1
				[promo-e logo-s nav-s utility-s]
				auto //2
				[end logo-e nav-e utility-e background-e]
				/
				[start promo-s background-s]
				30px
				[logo-s]
				auto //1
				[logo-e nav-s]
				1fr //2
				[]
				1fr //3
				[]
				1fr //4
				[]
				1fr //5
				[]
				1fr //6
				[]
				1fr //7
				[]
				1fr //8
				[]
				1fr //9
				[nav-e utility-s]
				1fr //10
				[]
				1fr //11
				[]
				1fr //12
				[utility-e]
				30px
				[end promo-e background-e];
		}
	}

	&.m-stuck.m-left_aligned &-utility_item.m-stores,
	&.m-stuck.m-left_aligned &-utility_item.m-ship_to {
		font-size: 0;
		margin: 0;
		max-width: 0;
		opacity: 0;
		padding: 0;
		visibility: hidden;
	}

	&.m-stuck .b-categories_navigation {
		&-list_1 {
			@include media(lg-up) {
				padding: rh(5 0 0);
			}
		}

		&-link_1 {
			@include media(lg-up) {
				padding: rh(0 0 5);
			}
		}
	}

	&.m-stuck.m-left_aligned .b-categories_navigation {
		&-list_1 {
			@include media(lg-up) {
				padding: rh(7 0 0);
			}
		}

		&-link_1 {
			@include media(lg-up) {
				padding: rh(0 0 7);
			}
		}
	}

	// Add vertical scroll if navigation flyout doesn't fit in the viewport
	.b-categories_navigation-group_1 {
		@include media(lg-up) {
			max-height: var(--header-space_without_header_height);
			overflow: auto;
		}
	}

	&.m-stuck .b-categories_navigation-group_1,
	&.m-stuck .b-categories_navigation-group_2 {
		@include media(md-down) {
			height: var(--header-space_without_header_height);
			top: var(--header-sticky-height);
		}
	}

	&.m-stuck .b-categories_navigation-item_1.m-with_subcategories::before {
		height: var(--header-space_without_header_height);
	}

	// LOGO SIZING
	.b-logo {
		--logo-width: #{$configuration-logo-width};
		--logo-image-height: #{$configuration-logo-image-height};
		--logo-image-width: #{$configuration-logo-image-width};

		@include media(md-down) {
			--logo-width: #{$configuration-logo-width__md_down};
		}
	}

	&.m-left_aligned .b-logo {
		--logo-width: #{$configuration-logo__portrait-width};
		--logo-image-height: #{$configuration-logo-image__portrait-height};
		--logo-image-width: #{$configuration-logo-image__portrait-width};
	}

	&.m-stuck .b-logo {
		--logo-width: #{$configuration-logo__stuck-width};

		@include media(md-down) {
			--logo-width: #{$configuration-logo__stuck-width__md_down};
		}
	}

	&.m-left_aligned.m-stuck .b-logo {
		--logo-width: #{$configuration-logo__stuck__portrait-width};
		--logo-image-height: #{$configuration-logo__stuck-image__portrait-height};
	}
}

.b-header-navigation_link.m-stores{
	font: $configuration-category_navigation-link-font;
	letter-spacing: $configuration-category_navigation-link-letter_spacing;
	text-transform: $configuration-category_navigation-link-text_tranform;
}